import React, { Component } from "react";
import { saveProfile } from "../functions/ApiCalls";
import { FormGroup, Label, Input } from "reactstrap";
import {
  BsFillCheckCircleFill,
  BsFillClockFill,
  BsFillXCircleFill,
} from "react-icons/bs";
import NumberFormat from "react-number-format";
import moment from "moment";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import { VisiblityCheck } from "../functions/ProfileHelper";

class ProfForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Profile: {
        UserId: "",
        FirstName: "",
        LastName: "",
        YearOfBirth: "",
        GenderObject: {},
        Gender: "",
        CountryObject: {},
        Country: "",
        Telephone: "",
        TypeOfOrganisationObject: {},
        TypeOfOrganisation: "",
        ProfessionalStatusObject: {},
        ProfessionalStatus: "",
        OrganisationOptions: [],
        OrganisationObject: {},
        Organisation: "",
        OrganisationName: "",
        SectorObject: {},
        Sector: "",
        SubSectorObject: {},
        SubSector: "",
        SubSectorOptions: [],
        AgeOfTopManagerObject: {},
        GenderOfTopManagerObject: {},
        AgeOfTopManager: "",
        GenderOfTopManager: "",
        SizeOfBusinessObject: {},
        SizeOfBusiness: "",
        UpdateDate: "",
        CreateDate: "",
        Email: "",
        RegistrationId: "",
      },
      Visiblity: {
        ShowSector: false,
        ShowTopManager: false,
        ShowSizeOfBusiness: false,
        ShowOrgName: false,
      },
      Success: true,
      Failed: false,
      Wait: false,
      GenderList: [],
      TypeoforganisationList: [],
      ProfessionalstatusList: [],
      CountryList: [],
      OrganisationList: [],
      SectorList: [],
      SubsectorList: [],
      SizeofbusinessList: [],
      AgeoftopmanagerList: [],
    };
  }

  componentDidMount() {
    this.FilterList(this.props.masterlist);
    this.FillProfile(this.props.profile);
  }
  // Filter the list according to the dropdown to give the options
  FilterList(master_list) {
    var state = this.state;
    state.GenderList = master_list.filter((list) => {
      return list.ListType === "gender" && list.IsActive;
    });
    /* const TitleList = master_list.filter((list) => {
      return list.ListType === "title";
    }); */
    /* const ToolreferenceList = master_list.filter((list) => {
      return list.ListType === "toolreference";
    }); */
    state.TypeoforganisationList = master_list.filter((list) => {
      return list.ListType === "typeoforganisation" && list.IsActive;
    });
    state.ProfessionalstatusList = master_list.filter((list) => {
      return list.ListType === "professionalstatus" && list.IsActive;
    });
    state.ProfessionalstatusList.sort((p1, p2) =>
      p1.ListOrder > p2.ListOrder ? 1 : -1
    );
    state.CountryList = master_list.filter((list) => {
      return list.ListType === "country" && list.IsActive;
    });
    state.OrganisationList = master_list.filter((list) => {
      return list.ListType === "organisation" && list.IsActive;
    });
    state.SectorList = master_list.filter((list) => {
      return list.ListType === "sector" && list.IsActive;
    });
    state.SubsectorList = master_list.filter((list) => {
      return list.ListType === "subsector" && list.IsActive;
    });
    state.SizeofbusinessList = master_list.filter((list) => {
      return list.ListType === "sizeofbusiness" && list.IsActive;
    });
    state.AgeoftopmanagerList = master_list.filter((list) => {
      return list.ListType === "ageoftopmanager" && list.IsActive;
    });
    state.CountryList.sort((a, b) =>
      a.DisplayText > b.DisplayText ? 1 : b.DisplayText > a.DisplayText ? -1 : 0
    );
    this.setState(state);
  }
  // Get the time zone
  GetTimeZone() {
    var date = new Date().toISOString().charAt(23);
    return date.substring(date.length - 1);
  }
  // Make the changes in the profile whenever the change is made in the options in the dropdown
  EditProfileDropDown(field, value) {
    var profile = this.state.Profile;
    profile[field] = value;
    switch (field) {
      case "GenderObject":
        profile.Gender = value.DataValue;
        break;
      case "CountryObject":
        profile.Country = value.DataValue;
        break;
      case "ProfessionalStatusObject":
        profile.ProfessionalStatus = value.DataValue;
        profile.TypeOfOrganisation = "";
        profile.TypeOfOrganisationObject = {};
        profile.Organisation = "";
        profile.OrganisationObject = {};
        profile.OrganisationName = "";
        profile.Sector = "";
        profile.SectorObject = {};
        profile.SubSector = "";
        profile.SubSectorObject = {};
        profile.AgeOfTopManagerObject = {};
        profile.AgeOfTopManager = "";
        profile.GenderOfTopManagerObject = {};
        profile.GenderOfTopManager = "";
        profile.SizeOfBusinessObject = {};
        profile.SizeOfBusiness = "";
        break;
      case "TypeOfOrganisationObject":
        profile.TypeOfOrganisation = value.DataValue;
        profile.OrganisationOptions = this.state.OrganisationList.filter(
          (list) => list.ParentId === value.Id
        );
        profile.Organisation = "";
        profile.OrganisationObject = {};
        profile.OrganisationName = "";
        profile.Sector = "";
        profile.SectorObject = {};
        profile.SubSector = "";
        profile.SubSectorObject = {};
        profile.AgeOfTopManagerObject = {};
        profile.AgeOfTopManager = "";
        profile.GenderOfTopManagerObject = {};
        profile.GenderOfTopManager = "";
        profile.SizeOfBusinessObject = {};
        profile.SizeOfBusiness = "";
        break;
      case "OrganisationObject":
        profile.Organisation = value.DataValue;
        profile.OrganisationName = "";
        profile.Sector = "";
        profile.SectorObject = {};
        profile.SubSector = "";
        profile.SubSectorObject = {};
        profile.AgeOfTopManagerObject = {};
        profile.AgeOfTopManager = "";
        profile.GenderOfTopManagerObject = {};
        profile.GenderOfTopManager = "";
        profile.SizeOfBusinessObject = {};
        profile.SizeOfBusiness = "";
        break;
      case "SectorObject":
        profile.Sector = value.DataValue;
        profile.SubSectorOptions = this.state.SubsectorList.filter(
          (subsec) => subsec.ParentId === value.Id
        );
        profile.SubSector = "";
        profile.SubSectorObject = {};
        profile.AgeOfTopManagerObject = {};
        profile.AgeOfTopManager = "";
        profile.GenderOfTopManagerObject = {};
        profile.GenderOfTopManager = "";
        profile.SizeOfBusinessObject = {};
        profile.SizeOfBusiness = "";
        break;
      case "SubSectorObject":
        profile.SubSector = value.DataValue;
        profile.SizeOfBusinessObject = {};
        profile.SizeOfBusiness = "";
        profile.AgeOfTopManagerObject = {};
        profile.AgeOfTopManager = "";
        profile.GenderOfTopManagerObject = {};
        profile.GenderOfTopManager = "";
        break;
      case "SizeOfBusinessObject":
        profile.SizeOfBusiness = value.DataValue;
        profile.AgeOfTopManagerObject = {};
        profile.AgeOfTopManager = "";
        profile.GenderOfTopManagerObject = {};
        profile.GenderOfTopManager = "";
        break;
      case "AgeOfTopManagerObject":
        profile.AgeOfTopManager = value.DataValue;
        profile.GenderOfTopManagerObject = {};
        profile.GenderOfTopManager = "";
        break;
      case "GenderOfTopManagerObject":
        profile.GenderOfTopManager = value.DataValue;
        break;
      default:
        break;
    }
    var visibility = VisiblityCheck(profile, this.state.Visiblity);
    this.setState({
      Profile: profile,
      Success: false,
      Failed: false,
      Wait: true,
      Visiblity: visibility,
    });
    this.SaveToBackend();
  }
  // Make the changes in the profile whenever the change is made in the text field
  EditProfileTextField(field, value) {
    var profile = this.state.Profile;
    profile[field] = value;
    this.setState({
      Profile: profile,
    });
    this.setState({
      Success: false,
      Failed: false,
      Wait: true,
    });
  }
  // Prefilling the profile with the previous options
  FillProfile(main_profile) {
    var profile = this.state.Profile;
    profile.UserId = main_profile.UserId;
    profile.Email = main_profile.Email;
    profile.CreateDate = main_profile.CreateDate;
    profile.RegistrationId = main_profile.RegistrationId;
    if (main_profile.UpdateDate) {
      profile.UpdateDate = main_profile.UpdateDate;
    }
    if (main_profile.FirstName) {
      profile.FirstName = main_profile.FirstName;
    }
    if (main_profile.LastName) {
      profile.LastName = main_profile.LastName;
    }
    if (main_profile.YearOfBirth) {
      profile.YearOfBirth = main_profile.YearOfBirth;
    }
    if (main_profile.Gender) {
      profile.Gender = main_profile.Gender;
      var gen = this.state.GenderList.find(
        (gender) => gender.DataValue === main_profile.Gender
      );
      profile.GenderObject = gen;
    }
    if (main_profile.Country) {
      profile.Country = main_profile.Country;
      var country = this.state.CountryList.find(
        (con) => con.DataValue === main_profile.Country
      );
      profile.CountryObject = country;
    }
    if (main_profile.Telephone) {
      profile.Telephone = main_profile.Telephone;
    }
    if (main_profile.ProfessionalStatus) {
      profile.ProfessionalStatus = main_profile.ProfessionalStatus;
      var prostatus = this.state.ProfessionalstatusList.find(
        (pro) => pro.DataValue === main_profile.ProfessionalStatus
      );
      profile.ProfessionalStatusObject = prostatus;
    }
    if (main_profile.TypeOfOrganisation) {
      profile.TypeOfOrganisation = main_profile.TypeOfOrganisation;
      var typeoforganisation = this.state.TypeoforganisationList.find(
        (org) => org.DataValue === main_profile.TypeOfOrganisation
      );
      profile.TypeOfOrganisationObject = typeoforganisation;
      profile.OrganisationOptions = this.state.OrganisationList.filter(
        (list) => list.ParentId === typeoforganisation.Id
      );
    }
    if (main_profile.Organisation) {
      profile.Organisation = main_profile.Organisation;
      var organisation = this.state.OrganisationList.find(
        (org) => org.DataValue === main_profile.Organisation
      );
      profile.OrganisationObject = organisation;
    }
    if (main_profile.OrganisationName) {
      profile.OrganisationName = main_profile.OrganisationName;
    }
    if (main_profile.Sector) {
      profile.Sector = main_profile.Sector;
      var sector = this.state.SectorList.find(
        (sec) => sec.DataValue === main_profile.Sector
      );
      profile.SectorObject = sector;
      profile.SubSectorOptions = this.state.SubsectorList.filter(
        (subsec) => subsec.ParentId === sector.Id
      );
    }
    if (main_profile.SubSector) {
      profile.SubSector = main_profile.SubSector;
      var subsector = this.state.SubsectorList.find(
        (sec) => sec.DataValue === main_profile.SubSector
      );
      profile.SubSectorObject = subsector;
    }
    if (main_profile.AgeOfTopManager) {
      profile.AgeOfTopManager = main_profile.AgeOfTopManager;
      var age = this.state.AgeoftopmanagerList.find(
        (ag) => ag.DataValue === main_profile.AgeOfTopManager
      );
      profile.AgeOfTopManagerObject = age;
    }
    if (main_profile.GenderOfTopManager) {
      profile.GenderOfTopManager = main_profile.GenderOfTopManager;
      var gender = this.state.GenderList.find(
        (gen) => gen.DataValue === main_profile.GenderOfTopManager
      );
      profile.GenderOfTopManagerObject = gender;
    }
    if (main_profile.SizeOfBusiness) {
      profile.SizeOfBusiness = main_profile.SizeOfBusiness;
      var size = this.state.SizeofbusinessList.find(
        (s) => s.DataValue === main_profile.SizeOfBusiness
      );
      profile.SizeOfBusinessObject = size;
    }
    var visbility = VisiblityCheck(profile, this.state.Visiblity);
    this.setState({
      Profile: profile,
      Visiblity: visbility,
    });
  }
  // Function to apply the changes to the backend
  async SaveToBackend() {
    var date = new Date().toISOString();
    date = date.substring(0, date.length - 1);
    var profile = this.state.Profile;
    for (const key in this.props.profile) {
      this.props.profile[key] = profile[key];
    }
    this.props.profile.UpdateDate = date;
    profile.UpdateDate = date;
    this.setState({
      Profile: profile,
    });
    var response = await saveProfile(
      this.props.profile,
      this.props.accessToken
    );
    if (response.status === 200 || response.status === 201) {
      this.setState({
        Success: true,
        Failed: false,
        Wait: false,
      });
    } else {
      this.setState({
        Success: false,
        Failed: true,
        Wait: false,
      });
    }
  }
  render() {
    const { t, tReady } = this.props;
    if (!tReady) {
      return null;
    }
    return (
      <div className="col">
        <div style={{ padding: "3% 13%" }} className="theme-text">
          <div className="vstack " style={{ fontSize: "1.1rem" }}>
            <div>
              <h2>{t("menu_editprofile")}</h2>
            </div>
            <hr />
            <div className="hstack">
              <div className="col-6 text-start">
                <span className="fw-bold theme-text">Email</span>:{" "}
                {this.props.profile.Email}
              </div>
              <div style={{ fontSize: "1.1rem" }} className="col-6 text-end">
                {this.state.Success && (
                  <div>
                    <span>{t("Updated")}</span>{" "}
                    <BsFillCheckCircleFill color="green" />
                  </div>
                )}
                {this.state.Wait && (
                  <div>
                    <BsFillClockFill color="orange" />
                  </div>
                )}
                {this.state.Failed && (
                  <div>
                    <span>{t("Failed")}</span> <BsFillXCircleFill color="red" />
                  </div>
                )}
              </div>
            </div>
            <hr />
          </div>
          <div className="row text-start" style={{ fontSize: "1.1rem" }}>
            <div className="col-md-6 col-sm-12 gy-3">
              <FormGroup>
                <Label for="FirstName">{t("firstname")}</Label>
                <Input
                  onChange={(e) => {
                    this.EditProfileTextField("FirstName", e.target.value);
                  }}
                  onBlurCapture={(e) => {
                    this.SaveToBackend("FirstName");
                  }}
                  value={this.state.Profile.FirstName}
                  name="FirstName"
                  id="FirstName"
                />
              </FormGroup>
            </div>
            <div className="col-md-6 col-sm-12 gy-3">
              <FormGroup>
                <Label for="LastName">{t("lastname")}</Label>
                <Input
                  onChange={(e) => {
                    this.EditProfileTextField("LastName", e.target.value);
                  }}
                  onBlurCapture={(e) => {
                    this.SaveToBackend("LastName");
                  }}
                  value={this.state.Profile.LastName}
                  name="LastName"
                  id="LastName"
                />
              </FormGroup>
            </div>
            <div className="col-md-6 col-sm-12 gy-3">
              <FormGroup className="vstack">
                <Label for="YearOfBirth">{t("yearofbirth")}</Label>
                <NumberFormat
                  onChange={(e) => {
                    this.EditProfileTextField("YearOfBirth", e.target.value);
                  }}
                  onBlurCapture={(e) => {
                    this.SaveToBackend("YearOfBirth");
                  }}
                  className="form-control"
                  format="####"
                  value={this.state.Profile.YearOfBirth}
                  name="YearOfBirth"
                  id="YearOfBirth"
                />
              </FormGroup>
            </div>
            <div
              className="col-md-6 col-sm-12 gy-3"
              style={{ marginTop: "15px" }}
            >
              <FormGroup>
                <Label for="Gender">{t("gender")}</Label>
                <Select
                  classNamePrefix="selectBox"
                  options={this.state.GenderList}
                  getOptionLabel={(item) => t(item.DisplayText)}
                  getOptionValue={(item) => item.DataValue}
                  value={this.state.Profile.GenderObject}
                  onChange={(e) => {
                    this.EditProfileDropDown("GenderObject", e);
                  }}
                />
              </FormGroup>
            </div>
            <div className="col-md-6 col-sm-12 gy-3">
              <FormGroup>
                <Label for="Country">{t("country")}</Label>
                <Select
                  classNamePrefix="selectBox"
                  options={this.state.CountryList}
                  getOptionLabel={(item) => t(item.DisplayText)}
                  getOptionValue={(item) => item.DataValue}
                  value={this.state.Profile.CountryObject}
                  onChange={(e) => {
                    this.EditProfileDropDown("CountryObject", e);
                  }}
                />
              </FormGroup>
            </div>
            <div className="col-md-6 col-sm-12 gy-3">
              <FormGroup className="vstack">
                <Label for="Telephone">{t("telephone")}</Label>
                <Input
                  className="form-control"
                  onChange={(e) => {
                    this.EditProfileTextField("Telephone", e.target.value);
                  }}
                  onBlurCapture={(e) => {
                    this.SaveToBackend("Telephone");
                  }}
                  value={this.state.Profile.Telephone}
                  name="Telephone"
                  id="Telephone"
                />
              </FormGroup>
            </div>
            <div className="col-md-6 col-sm-12 gy-3">
              <FormGroup>
                <Label for="ProfessionalStatus">
                  {t("professionalcategory")}
                </Label>
                <Select
                  options={this.state.ProfessionalstatusList}
                  getOptionLabel={(item) => t(item.DisplayText)}
                  getOptionValue={(item) => item.DataValue}
                  value={this.state.Profile.ProfessionalStatusObject}
                  onChange={(e) => {
                    this.EditProfileDropDown("ProfessionalStatusObject", e);
                  }}
                />
              </FormGroup>
            </div>
            <div className="col-md-6 col-sm-12 gy-3">
              <FormGroup>
                <Label for="TypeOfOrganisation">
                  {t("typeoforganisation")}
                </Label>
                <Select
                  options={this.state.TypeoforganisationList}
                  getOptionLabel={(item) => t(item.DisplayText)}
                  getOptionValue={(item) => item.DataValue}
                  value={this.state.Profile.TypeOfOrganisationObject}
                  onChange={(e) => {
                    this.EditProfileDropDown("TypeOfOrganisationObject", e);
                  }}
                />
              </FormGroup>
            </div>
            {this.state.Profile.OrganisationOptions.length !== 0 && (
              <div className="col-md-6 col-sm-12 gy-3">
                <FormGroup>
                  <Label for="Organisation">{t("organisation")}</Label>
                  <Select
                    classNamePrefix="selectBox"
                    options={this.state.Profile.OrganisationOptions}
                    getOptionLabel={(item) => t(item.DisplayText)}
                    getOptionValue={(item) => item.DataValue}
                    value={this.state.Profile.OrganisationObject}
                    onChange={(e) => {
                      this.EditProfileDropDown("OrganisationObject", e);
                    }}
                  />
                </FormGroup>
              </div>
            )}
            {this.state.Visiblity.ShowOrgName && (
              <div className="col-md-6 col-sm-12 gy-3">
                <FormGroup>
                  <Label for="OrganisationName">
                    {t("nameoforganization")}
                  </Label>
                  <Input
                    onChange={(e) => {
                      this.EditProfileTextField(
                        "OrganisationName",
                        e.target.value
                      );
                    }}
                    onBlurCapture={(e) => {
                      this.SaveToBackend("OrganisationName");
                    }}
                    value={this.state.Profile.OrganisationName}
                    name="OrganisationName"
                    id="OrganisationName"
                  />
                </FormGroup>
              </div>
            )}
            {this.state.Visiblity.ShowSector && (
              <div className="col-md-6 col-sm-12 gy-3">
                <FormGroup>
                  <Label for="Sector">{t("sector")}</Label>
                  <Select
                    classNamePrefix="selectBox"
                    options={this.state.SectorList}
                    getOptionLabel={(item) => t(item.DisplayText)}
                    getOptionValue={(item) => item.DataValue}
                    value={this.state.Profile.SectorObject}
                    onChange={(e) => {
                      this.EditProfileDropDown("SectorObject", e);
                    }}
                  />
                </FormGroup>
              </div>
            )}
            {this.state.Visiblity.ShowSector && (
              <div className="col-md-6 col-sm-12 gy-3">
                <FormGroup>
                  <Label for="SectorSubCategory">{t("subsector")}</Label>
                  <Select
                    classNamePrefix="selectBox"
                    options={this.state.Profile.SubSectorOptions}
                    getOptionLabel={(item) => t(item.DisplayText)}
                    getOptionValue={(item) => item.DataValue}
                    value={this.state.Profile.SubSectorObject}
                    onChange={(e) => {
                      this.EditProfileDropDown("SubSectorObject", e);
                    }}
                  />
                </FormGroup>
              </div>
            )}
            {this.state.Visiblity.ShowSizeOfBusiness && (
              <div className="col-md-6 col-sm-12 gy-3">
                <FormGroup>
                  <Label for="SizeOfBusiness">{t("sizeofbusiness")}</Label>
                  <Select
                    classNamePrefix="selectBox"
                    options={this.state.SizeofbusinessList}
                    getOptionLabel={(item) => t(item.DisplayText)}
                    getOptionValue={(item) => item.DataValue}
                    value={this.state.Profile.SizeOfBusinessObject}
                    onChange={(e) => {
                      this.EditProfileDropDown("SizeOfBusinessObject", e);
                    }}
                  />
                </FormGroup>
              </div>
            )}
            {this.state.Visiblity.ShowTopManager && (
              <div className="col-md-6 col-sm-12 gy-3">
                <FormGroup>
                  <Label for="AgeOfTopManager">{t("ageoftopmanager")}</Label>
                  <Select
                    classNamePrefix="selectBox"
                    options={this.state.AgeoftopmanagerList}
                    getOptionLabel={(item) => t(item.DisplayText)}
                    getOptionValue={(item) => item.DataValue}
                    value={this.state.Profile.AgeOfTopManagerObject}
                    onChange={(e) => {
                      this.EditProfileDropDown("AgeOfTopManagerObject", e);
                    }}
                  />
                </FormGroup>
              </div>
            )}
            {this.state.Visiblity.ShowTopManager && (
              <div className="col-md-6 col-sm-12 gy-3">
                <FormGroup>
                  <Label for="GenderOfTopManager">
                    {t("genderoftopmanager")}
                  </Label>
                  <Select
                    classNamePrefix="selectBox"
                    options={this.state.GenderList}
                    getOptionLabel={(item) => t(item.DisplayText)}
                    getOptionValue={(item) => item.DataValue}
                    value={this.state.Profile.GenderOfTopManagerObject}
                    onChange={(e) => {
                      this.EditProfileDropDown("GenderOfTopManagerObject", e);
                    }}
                  />
                </FormGroup>
              </div>
            )}
          </div>
          <br />
          <hr />
          <div className="text-end" style={{ margin: "0 0 1.5rem 0" }}>
            <span className="fw-bold theme-text">{t("UpdateDate")}</span>:{" "}
            {moment(this.state.Profile.UpdateDate + this.GetTimeZone()).format(
              "DD/MM/yyyy HH:mm:ss"
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProfForm);
