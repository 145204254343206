import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import IframeForm from "./components/IframeForm";
import EditProfile from "./components/EditProfile";
import TermsOfUse from "./components/TermsOfUse";
import TermsAndConditions from "./components/TermsAndConditions";
import WebsitePrivacyAndCookie from "./components/WebsitePrivacyAndCookie";
import InterfacePrivacyAndCookie from "./components/InterfacePrivacyAndCookie";
import PrivacyPolicy from "./components/PrivacyPolicy";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/editprofile" component={EditProfile} />
          <Route path="/iframe" component={IframeForm} />
          {/* <Route
            exact
            path="/website-privacy-and-cookie"
            component={WebsitePrivacyAndCookie}
          /> */}
          {/* <Route
            exact
            path="/interface-privacy-and-cookie"
            component={InterfacePrivacyAndCookie}
          /> */}
          {/* <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          /> */}
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Redirect from="/" to="/editprofile" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
