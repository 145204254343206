import Header from "./Header";
import Footer from "./Footer";
export default function TermsOfUse() {
  return (
    <div>
      <Header showLanguage={false} />
      <div className="vstack page-width">
        <h3 style={{ marginTop: "30px" }}>TERMS OF USE</h3>
        <div style={{ padding: "20px 100px" }} className="vstack text-start">
          <h5 className="JustifyTerms">
            The International Trade Centre (hereinafter referred to as “ITC”),
            with headquarters in Geneva, Switzerland, is a joint subsidiary
            organ of the United Nations and the World Trade Organization
            (hereinafter collectively referred to as the “Parent
            Organizations”). Please read these Terms of Use carefully before
            proceeding to access and use ITC's webpages, as it sets out the
            terms of a legally binding agreement between you (hereinafter the
            “User”) and ITC. Registered users of our Website, Products and
            Services are “Registrants”.
          </h5>
          <div className="vstack JustifyTerms">
            <ol>
              <h4>
                <li className="nested-list-0">
                  AGREEMENT
                  <h5 className="nested-list-1">
                    By accessing, using or registering to our Website, products
                    and services (described below), you are agreeing to enter
                    into a legally binding contract with ITC (even if you are
                    using our Services on behalf of an entity). If you do not
                    agree to these Terms of Use, do not access or otherwise use
                    this Website or any related Products or Services nor proceed
                    with the registration process.
                  </h5>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  PRIVACY POLICY
                  <h5 className="nested-list-1">
                    As a part of accessing or using the Website and associated
                    Products and Services, ITC may collect, access, use and
                    share certain Personal Information and Cookies (as defined
                    in the <a href="/privacy-policy/">Privacy Policy</a>) from,
                    and/or about, you. Please read ITC{" "}
                    <a href="/privacy-policy/">Privacy Policy</a>, which is
                    incorporated herein by reference, for a description of such
                    data collection and use practices.
                  </h5>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  WEBSITE AND ASSOCIATED PRODUCTS AND SERVICES
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        ITC offers products and services accessible through its
                        Website (hereinafter the "Products" and "Services").
                        Should the User or Registrant not agree to these Terms
                        of Use, the User or Registrant should not continue the
                        use and/or registration with ITC's Website.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC grants permission to Registrants to use products and
                        services accessible through registration.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC grants permission to the User to visit the Website
                        and to consult documents, presentations and materials
                        made available online (hereinafter collectively, the
                        “Informative Material”) for the User’s personal and
                        non-commercial use only, without any right to resell or
                        redistribute them or to compile or create derivative
                        works there from, subject to the terms outlined below,
                        and also subject to more specific provisions that may
                        apply to specific Informative Material within this
                        Website.
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>

              <h4>
                <li className="nested-list-0">
                  BEHAVIOUR GUIDELINES
                  <h5 className="nested-list-1">
                    This Website may contain bulletin boards, interactive forms,
                    feedback, contact pages, discussion fora, frequently asked
                    questions (FAQs) and answers, access to mailing lists or
                    other message or communication facilities (hereinafter
                    collectively, “Forums”) and private communication channels
                    to be used in the context of the Services delivery
                    (hereinafter: “User’s Interaction Tools”), the Registrant
                    agrees to use the Forums and the User’s Interaction Tools
                    only to send and receive messages and materials that are
                    proper and related to the particular Forum and/or User’s
                    Interaction Tool. By way of example and not as a limitation,
                    the Registrant agrees that when using a Forum and/or User’s
                    Interaction Tool, he or she shall not do any of the
                    following:
                    <div className="vstack">
                      <div className="nested-list-1 hstack">
                        <div className="alphabet-list-order">a{")"}</div>
                        <div className="alphabet-list-value">
                          defame, abuse, harass, stalk, threaten or otherwise
                          violate the legal rights (such as rights of privacy
                          and publicity) of others;
                        </div>
                      </div>
                      <div className="nested-list-1 hstack">
                        <div className="alphabet-list-order">b{")"}</div>
                        <div className="alphabet-list-value">
                          publish, post, distribute or disseminate any
                          defamatory, infringing, obscene, indecent or unlawful
                          material or information;
                        </div>
                      </div>
                      <div className="nested-list-1 hstack">
                        <div className="alphabet-list-order">c{")"}</div>
                        <div className="alphabet-list-value">
                          upload or attach files that contain software or other
                          material protected by intellectual property laws (or
                          by rights of privacy and publicity) unless the User
                          owns or controls the rights thereto or has received
                          all consents as may be required by law;
                        </div>
                      </div>
                      <div className="nested-list-1 hstack">
                        <div className="alphabet-list-order">d{")"}</div>
                        <div className="alphabet-list-value">
                          upload or attach files that contain viruses, corrupted
                          files or any other similar software or programs that
                          may damage the operation of another’s computer;
                        </div>
                      </div>
                      <div className="nested-list-1 hstack">
                        <div className="alphabet-list-order">e{")"}</div>
                        <div className="alphabet-list-value">
                          delete any author attributions, legal notices or
                          proprietary designations or labels in any file that is
                          uploaded
                        </div>
                      </div>
                      <div className="nested-list-1 hstack">
                        <div className="alphabet-list-order">f{")"}</div>
                        <div className="alphabet-list-value">
                          falsify the origin or source of software or other
                          material contained in a file that is uploaded;
                        </div>
                      </div>
                      <div className="nested-list-1 hstack">
                        <div className="alphabet-list-order">g{")"}</div>
                        <div className="alphabet-list-value">
                          advertise or offer to sell any goods or services, or
                          conduct or forward surveys, contests or chain letters,
                          or download any file posted by another user of a Forum
                          and/or User’s Interaction Tool that the Registrant
                          knows, or reasonably should know, cannot be legally
                          distributed in such manner.
                        </div>
                        0
                      </div>
                    </div>
                  </h5>
                  <h5 className="nested-list-1">
                    The Registrant acknowledges that all Forums and discussion
                    groups are public and not private communications. Further,
                    the Registrant acknowledges that chats, postings,
                    conferences, e-mails and other communications by other
                    Registrants are not endorsed by the ITC unless expressly
                    stated otherwise, and that such communications shall not be
                    considered to have been reviewed, screened or approved by
                    the ITC. ITC reserves the right to remove, for any reason
                    and without notice, any content of the Forums and/or User
                    Interaction Tools received from the Registrant, including,
                    without limitation, e-mail and bulletin board postings.
                  </h5>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  THIRD-PARTY WEBSITES
                  <h5 className="nested-list-1">
                    ITC’s Website may include links and references to
                    third-party websites. Clicking on those links or enabling
                    those connections may allow third parties to collect or
                    share data about you including your Personal Data. ITC has
                    no control over, and is not responsible for, these
                    third-party websites or how they process data, and ITC is
                    not responsible for the privacy policies and practices of
                    any third party. ITC provides these links only as a
                    convenience, and the inclusion of a link or reference does
                    not imply the endorsement of the Linked Website by ITC. When
                    you leave the Website, ITC encourages you to read the
                    privacy policy of every website you visit.
                  </h5>
                </li>
              </h4>

              <h4>
                <li className="nested-list-0">
                  DISCLAIMERS
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        This Website and any of its contents including the
                        Informative Material and associated Products and
                        Services provided on this Website are provided “as is”
                        and “with all faults” and are subject to change, without
                        warranty or representation of any kind, either express
                        or implied, including, without limitation, warranties of
                        merchantability, fitness for a particular purpose and
                        non-infringement. ITC specifically does not make any
                        warranties or representations as to the accuracy or
                        completeness of the Website and any of its contents,
                        including the Informative Material and associated
                        Products and Services. ITC periodically adds, changes,
                        improves or updates this Website and its contents
                        including the Informative Material and associated
                        Services without any notice.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        The availability of the Website, any of its contents
                        including the Informative Material and associated
                        Services are not guaranteed. ITC does not warrant or
                        promise that the Website and any of its contents
                        including the Informative Material and the Services will
                        always be available or fully functional and reserve the
                        right to withdraw the Website, any of its contents
                        including the Informative Material and the Services at
                        any time.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        The Website may contain advice, opinions and statements
                        of various information providers. ITC does not represent
                        or endorse the accuracy or reliability of any advice,
                        opinion, statement or other information provided by any
                        information provider, any User or Registrant of this
                        Website or any other person or entity. Reliance upon any
                        such advice, opinion, statement, or other information
                        shall also be at the User’s or Registrant’s own risk.
                        Neither ITC nor its Parent Organizations, nor any of
                        their respective agents, employees, information
                        providers or content providers, shall be liable to any
                        User, Registrant or anyone else for any inaccuracy,
                        error, omission, interruption, deletion, defect,
                        alteration of or use of any content herein, or for its
                        timeliness or completeness, nor shall they be liable for
                        any failure of performance, computer virus or
                        communication line failure, regardless of cause, or for
                        any damages resulting therefrom.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        The designations employed and contained on the Website,
                        any of its contents including the Informative Material
                        and associated Products and Services, do not imply the
                        expression of any opinion whatsoever on the part of ITC
                        and its Parent Organizations concerning the legal status
                        of any country, territory, city or area or of its
                        authorities, or concerning the delimitation of its
                        frontiers or boundaries. The term “country” as used on
                        the Website, any of its contents including the
                        Informative Material and associated Products and
                        Services, also refers, as appropriate, to territories or
                        areas.
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>

              <h4>
                <li className="nested-list-0">
                  EXCLUSION OF LIABILITY
                  <h5 className="nested-list-1">
                    Under no circumstances whatsoever, including but not limited
                    to negligence, shall ITC or its Parent Organizations be
                    liable for any direct, indirect, incidental, special or
                    consequential costs, losses, claims, damages, expenses or
                    proceedings of whatever nature incurred or suffered by any
                    User or Registrant arising directly or indirectly in
                    connection with their use of this Website, any of its
                    contents including the Informative Material and the Products
                    and Services, including without limitation, any fault,
                    error, omission, interruption or delay with respect thereto
                    or due to any unavailability of parts or all of the Website
                    and any of its contents including the Informative Material
                    and the Services, even if the ITC has been advised of the
                    possibility of such damages. The User or Registrant
                    specifically acknowledges and agrees that the ITC and its
                    Parent Organizations are not liable for any conduct of any
                    User or Registrant.
                  </h5>
                </li>
              </h4>

              <h4>
                <li className="nested-list-0">
                  INDEMNITY
                  <h5 className="nested-list-1">
                    As a condition of use of this Website, the User or
                    Registrant agrees to indemnify and hold harmless ITC and its
                    Parent Organizations from and against any and all actions,
                    claims, losses, damages, liabilities and expenses (including
                    reasonable attorneys’ fees) arising out or in connection
                    with the User’s or Registrant’s use of this Website, any of
                    its contents including the Informative Material and
                    associated Products and Services, including, without
                    limitation, any claims alleging facts that if true would
                    constitute a breach by the User or Registrant of these Terms
                    of Use. If the User or Registrant is dissatisfied with this
                    Website, any of its contents including the Informative
                    Material and the Services or with its Terms of Use, the
                    User’s or Registrant’s sole and exclusive remedy is to
                    discontinue the use and registration on the Website, its
                    contents or associated Products and Services.
                  </h5>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  INTELLECTUAL PROPERTY RIGHTS
                  <ol>
                    <h5>
                      <li className="nested-list-0">
                        All content included in or made available through the
                        Website, including the Informative Material, the
                        associated Products and Services, the Service Material
                        (as define below) and material such as software,
                        application programming interface, design, text,
                        graphics, button icons, editorial materials,
                        informational text, photographs, illustrations, audio
                        clips, video clips, artwork and other graphic materials,
                        and names, logos, trademarks and services marks, digital
                        downloads, data compilations and any and all related or
                        underlying technology and any modifications,
                        enhancements or derivative works of the foregoing
                        (collectively, “ITC Intellectual Property”) is the
                        exclusively property of ITC may be protected by
                        applicable copyright or other intellectual property laws
                        and treaties.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-0">
                        Except for the Informative Material (as set out in
                        Clause 3 above), the User or Registrant may not extract
                        and/or re-utilise parts of the ITC Intellectual
                        Property.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-0">
                        ITC Intellectual Property may not be used in connection
                        with any product or service that is not ITC's, in any
                        manner that is likely to cause confusion among
                        beneficiaries or in any manner that disparages or
                        discredits ITC. All other intellectual proprieties not
                        owned by ITC that may appear on the Website are the
                        property of their respective owners, who may or may not
                        be affiliated with or connected to ITC.
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">LICENSE AND OWNERSHIP</li>
                <h5 className="nested-list-1">
                  Any tools, methodologies, documents or other materials
                  (collectively referred as to the “Service Material”)
                  distributed or rendered accessible as the case may be by ITC
                  to the User or Registrant in the framework of the Services are
                  provided to the User or Registrant on a non-exclusive basis as
                  a means to ensure timely implementation of activities under a
                  specific project and technical work on a non-commercial basis,
                  uniquely for use of the User or Registrant. The User or
                  Registrant acknowledges and agrees that all rights, title and
                  interests in and to the Service Material, including without
                  limitation the copyright to the Service Material and all
                  intellectual property rights therein, belong to and are
                  possessed or owned by ITC and that ITC retains the right to
                  make such use of the Services Material as it may consider
                  necessary in its sole discretion. The User or Registrant shall
                  therefore refrain from attributing in any manner whatsoever
                  the Service Material or any portion thereof to itself or to
                  any person or entity other than ITC.
                </h5>
              </h4>
              <h4>
                <li className="nested-list-0">ITC LEGAL STATUS</li>
                <ol>
                  <h5>
                    <li className="nested-list-1">
                      ITC enjoys privileges and immunities by the Convention on
                      the privileges and immunities of the United Nations
                      adopted by the General Assembly of the United Nations on
                      13th February 1946 (hereinafter the “Privileges and
                      Immunities”).
                    </li>
                    <li className="nested-list-1">
                      Nothing contained in or relating to these Terms of Use
                      shall constitute or be considered to be a limitation upon
                      or a waiver, express or implied, of the Privileges and
                      Immunities of the ITC, which are specifically reserved.
                    </li>
                  </h5>
                </ol>
              </h4>

              <h4>
                <li className="nested-list-0">DISPUTE SETTLEMENT</li>
                <h5 className="nested-list-1">
                  Any dispute, controversy or claim arising out of these Terms
                  of Use shall, unless settled amicably by direct negotiations
                  in accordance with the UNCITRAL Conciliation Rules, be
                  referred to arbitration in accordance with the UNICTRAL
                  Arbitration Rules. Both ITC and the User or Registrant shall
                  be bound by the arbitration award rendered in accordance with
                  such arbitration, as the final adjudication of any such
                  dispute, controversy or claim.
                </h5>
              </h4>
              <h4>
                <li className="nested-list-0">GENERAL RIGHTS OF ITC</li>
                <ol>
                  <h5>
                    <li className="nested-list-1">
                      ITC reserves its exclusive right in its sole discretion to
                      alter, limit or discontinue the Website, any of its
                      contents including the Informative Material and associated
                      Products and Services in any respect. ITC shall have no
                      obligation to take the needs of any User or Registrant
                      into consideration in connection therewith.
                    </li>
                    <li className="nested-list-1">
                      ITC reserves the right to deny in its sole discretion any
                      User or Registrant access to this Website or any portion
                      thereof and any of its contents including the Informative
                      Material and associated Products and Services without any
                      prior notice.
                    </li>
                    <li className="nested-list-1">
                      No waiver by ITC of any provision of these Terms of Use
                      shall be binding except as set forth in writing and signed
                      by its duly authorized representative.
                    </li>
                  </h5>
                </ol>
              </h4>

              <h4>
                <li className="nested-list-0">AMENDMENTS</li>
                <h5 className="nested-list-1">
                  We may update these Terms of Use from time to time by
                  publishing a new version on the Website. ITC thus encourages
                  you to visit this section of the Website regularly in order to
                  keep up to date with any changes. Changes to the Terms of Use
                  will apply from the date they are posted on the Website and
                  shall not apply retroactively.
                </h5>
              </h4>
              <h4>
                <li className="nested-list-0">CONTACT DETAILS</li>
                <h5 className="nested-list-1">
                  You can contact ITC to verify or rectify your Personal Data
                  using the ITC Website contact form{" "}
                  <a
                    href="https://www.intracen.org/contact-us/"
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    here
                  </a>{" "}
                  or by post to:
                  <br />
                  <br />
                  International Trade Centre
                  <br />
                  Palais des Nations
                  <br />
                  CH-1211 Geneva 10
                  <br />
                  Switzerland
                </h5>
              </h4>
              <h4>
                <li className="nested-list-0">VERSION DETAILS</li>

                <h5 className="nested-list-1">
                  This policy was last updated 01 November 2022 and supersedes
                  all previous versions.
                </h5>
              </h4>
            </ol>
          </div>
        </div>
      </div>
      <Footer showLinks={true} />
    </div>
  );
}
