export function VisiblityCheck(profile, visibility) {
  if (
    (profile.TypeOfOrganisation === "Business" &&
      (profile.ProfessionalStatus === "Senior-level employee" ||
        profile.ProfessionalStatus === "Mid-level/Junior employee")) ||
    profile.ProfessionalStatus === "Business owner/ entrepreneur" ||
    profile.ProfessionalStatus === "Self-employed/ Freelancer" ||
    profile.ProfessionalStatus === "Farmer" ||
    profile.ProfessionalStatus === "Artisan/Craftsperson"
  ) {
    visibility.ShowSector = true;
  } else {
    visibility.ShowSector = false;
  }
  if (
    profile.TypeOfOrganisation === "Business" &&
    (profile.ProfessionalStatus === "Senior-level employee" ||
      profile.ProfessionalStatus === "Mid-level/Junior employee")
  ) {
    visibility.ShowTopManager = true;
  } else {
    visibility.ShowTopManager = false;
  }

  if (
    (profile.TypeOfOrganisation === "Business" &&
      (profile.ProfessionalStatus === "Senior-level employee" ||
        profile.ProfessionalStatus === "Mid-level/Junior employee")) ||
    profile.ProfessionalStatus === "Business owner/ entrepreneur"
  ) {
    visibility.ShowSizeOfBusiness = true;
  } else {
    visibility.ShowSizeOfBusiness = false;
  }
  if (
    profile.TypeOfOrganisation !== "" &&
    profile.TypeOfOrganisation !== "None, I am independent" &&
    profile.ProfessionalStatus !== "" &&
    (profile.ProfessionalStatus !== "Self-employed/ Freelancer" ||
      profile.ProfessionalStatus !== "Farmer" ||
      profile.ProfessionalStatus !== "Artisan/Craftsperson")
  ) {
    visibility.ShowOrgName = true;
  } else {
    visibility.ShowOrgName = false;
  }
  return visibility;
}
