import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import i18n from "../i18n/config";
import { withTranslation } from "react-i18next";
import SignOutButton from "./SignOutButton";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Language: {
        Id: 4,
      },
      LanguageList: [],
    };
  }
  componentDidMount() {
    var state = this.state;
    if (this.props.showLanguage === true) {
      // Fill all language options with the available options
      state.LanguageList = this.props.masterlist.filter((list) => {
        return list.ListType === "language";
      });
    }
    this.setState(state);
  }
  // Function to make the language changes when different langauage is selected
  changeLanguage(value) {
    var language = this.state.Language;
    language.Id = parseInt(value);
    var lang = this.state.LanguageList.find(
      (lng) => lng.Id === parseInt(value)
    );
    if (lang) {
      let languageCode = "en";
      switch (lang.DataValue) {
        case "English":
          languageCode = "en";
          break;
        case "French":
          languageCode = "fr";
          break;
        case "Spanish":
          languageCode = "es";
          break;
        default:
          languageCode = "en";
          break;
      }
      i18n.changeLanguage(languageCode);
    }
    this.setState({
      Language: language,
    });
  }
  render() {
    const { t, tReady } = this.props;
    if (!tReady) {
      return null;
    }
    return (
      <div>
        <div
          className="align-items-center hstack gap-2"
          style={{ padding: "25px" }}
        >
          <div>
            <img src="/images/logo.svg" alt="Home" width="100px"></img>
          </div>
          {this.props.showLanguage ? (
            <div className="ms-auto">
              <FormGroup>
                <Input
                  type="select"
                  value={this.state.Language.Id}
                  onChange={(e) => {
                    this.changeLanguage(e.target.value);
                  }}
                >
                  {this.state.LanguageList.map((lang) => (
                    <option key={lang.Id} value={lang.Id}>
                      {lang.DisplayText}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </div>
          ) : (
            <div className="ms-auto">
              <a className="Links theme-text" href="/editprofile">
                {t("menu_editprofile")}
              </a>
            </div>
          )}
          <SignOutButton t={t} />
        </div>
        <div
          style={{ width: "100%", height: "35px", backgroundColor: "#b8014c" }}
        ></div>
      </div>
    );
  }
}

export default withTranslation()(Header);
