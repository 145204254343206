import React from "react";

export default function Footer({ showLinks }) {
  return (
    <div className="footer" style={{ marginTop: "10px" }}>
      <footer
        className="itc-footer"
        style={{
          marginTop: "40px",
          width: "100%",
          color: "white",
          backgroundColor: "#002c53",
          padding: "3px",
        }}
      >
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-md-2 text-center">
              {showLinks && (
                <a
                  className="Links"
                  href="/terms-of-use"
                  style={{ color: "white" }}
                  target="_blank"
                >
                  Terms of Use
                </a>
              )}
            </div>
            {/* <div className="col-md-2 text-center">
              <a
                className="Links"
                href="/terms-and-conditions"
                style={{ color: "white" }}
                target="_blank"
              >
                Terms and Conditions
              </a>
            </div> */}
            <div className="col-md-4 text-center">
              &copy; International Trade Centre -{" "}
              <span id="currentYear">{new Date().getFullYear()}</span>
            </div>
            <div className="col-md-2 text-center">
              {showLinks && (
                <a
                  className="Links"
                  href="/privacy-policy"
                  style={{ color: "white" }}
                  target="_blank"
                >
                  Privacy policy
                </a>
              )}
            </div>
            {/* <div className="col-md-2 text-center">
              <a
                className="Links"
                href="/interface-privacy-and-cookie"
                style={{ color: "white" }}
                target="_blank"
              >
                Interface Privacy and Cookies
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
}
