import React, { useState, useEffect } from "react";
import ProfileForm from "./ProfileForm";
import { useMsal } from "@azure/msal-react";
import { fetchProfile, GetMasterList } from "../functions/ApiCalls";
import { protectedResources } from "../AuthConfig";

function IframeForm() {
  const { instance } = useMsal();
  const [profile, setProfile] = useState({});
  const [MasterList, setMasterList] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  useEffect(() => {
    async function handleRedirect() {
      // Get All accounts in the msal Instance
      const accounts = await instance.getAllAccounts();
      if (accounts.length > 0) {
        // If any account then set the first account as active account
        await instance.setActiveAccount(accounts[0]);
      }
      // Check if user signed in
      const account = await instance.getActiveAccount();
      if (account) {
        // If present then fetch the access token silently
        await instance
          .acquireTokenSilent({
            scopes: protectedResources.api.scopes,
            account: account,
          })
          .then(async (response) => {
            setAccessToken(response.accessToken);
            setMasterList(await GetMasterList(response.accessToken));
            setProfile(await fetchProfile(response));
          })
          .catch(async (error) => {
            await instance
              .acquireTokenPopup({
                scopes: protectedResources.api.scopes,
              })
              .then(async (response) => {
                setAccessToken(response.accessToken);
                setMasterList(await GetMasterList(response.accessToken));
                setProfile(await fetchProfile(response));
              })
              .catch((error) => {
                console.log(error);
              });
          });
      } else {
        // Else acquire the account and access token with the help of popup
        await instance
          .acquireTokenPopup({
            scopes: protectedResources.api.scopes,
          })
          .then(async (response) => {
            setAccessToken(response.accessToken);
            setMasterList(await GetMasterList(response.accessToken));
            setProfile(await fetchProfile(response));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    handleRedirect();
  }, [instance]);

  /* const { token, setToken } = useState(""); */
  return (
    <div>
      {profile.Email ? (
        <div>
          <ProfileForm
            accessToken={accessToken}
            masterlist={MasterList}
            profile={profile}
          />
        </div>
      ) : (
        <div className="loading">
          <h2>Loading...</h2>
        </div>
      )}
    </div>
  );
}

export default IframeForm;
