// B2C Policies

const b2cPolicies_dev = {
  names: {
    signUpSignIn: "B2C_1_SignUp_SignIn",
    forgotPassword: "B2C_1_Password",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://itcssodev.b2clogin.com/itcssodev.onmicrosoft.com/B2C_1_SignUp_SignIn",
    },
    forgotPassword: {
      authority:
        "https://itcssodev.b2clogin.com/itcssodev.onmicrosoft.com/B2C_1_Password",
    },
  },
  authorityDomain: "itcssodev.b2clogin.com",
};

const b2cPolicies_local = {
  names: {
    signUpSignIn: "B2C_1_SignUp_SignIn",
    forgotPassword: "B2C_1_Password",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://itcssodev.b2clogin.com/itcssodev.onmicrosoft.com/B2C_1_SignUp_SignIn",
    },
    forgotPassword: {
      authority:
        "https://itcssodev.b2clogin.com/itcssodev.onmicrosoft.com/B2C_1_Password",
    },
  },
  authorityDomain: "itcssodev.b2clogin.com",
};

const b2cPolicies_prod = {
  names: {
    signUpSignIn: "B2C_1_SignUp_SignIn",
    forgotPassword: "B2C_1_Password",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://itcsso.b2clogin.com/itcsso.onmicrosoft.com/B2C_1_SignUp_SignIn",
    },
    forgotPassword: {
      authority:
        "https://itcsso.b2clogin.com/itcsso.onmicrosoft.com/B2C_1_Password",
    },
  },
  authorityDomain: "itcsso.b2clogin.com",
};

export const b2cPolicies =
  process.env.REACT_APP_ENV === "local"
    ? b2cPolicies_local
    : process.env.REACT_APP_ENV === "development"
    ? b2cPolicies_dev
    : b2cPolicies_prod;

// MSAL Configs

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig_dev = {
  auth: {
    clientId: "d8d5cff6-0604-41a3-8502-6554281c0812", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies_dev.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies_dev.authorityDomain], // Mark your B2C tenant's domain as trusted.
    //redirectUri: "https://users-dev.intracen.org/editprofile", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
const msalConfig_local = {
  auth: {
    clientId: "d8d5cff6-0604-41a3-8502-6554281c0812", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies_dev.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies_dev.authorityDomain], // Mark your B2C tenant's domain as trusted.
    //redirectUri: "http://localhost:3000/editprofile", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const msalConfig_prod = {
  auth: {
    clientId: "c63c957b-945f-4dee-8e0a-b97aaa32a16a", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies_prod.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies_prod.authorityDomain], // Mark your B2C tenant's domain as trusted.
    //redirectUri: "https://users.intracen.org/editprofile", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalConfig =
  process.env.REACT_APP_ENV === "local"
    ? msalConfig_local
    : process.env.REACT_APP_ENV === "development"
    ? msalConfig_dev
    : msalConfig_prod;

// Protected Resources

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const protectedResources_dev = {
  api: {
    scopes: [
      "https://itcssodev.onmicrosoft.com/profileapi-scope/Read.Profiles",
    ], // e.g. api://xxxxxx/access_as_user
  },
};
const protectedResources_local = {
  api: {
    scopes: [
      "https://itcssodev.onmicrosoft.com/profileapi-scope/Read.Profiles",
    ], // e.g. api://xxxxxx/access_as_user
  },
};

const protectedResources_prod = {
  api: {
    scopes: ["https://itcsso.onmicrosoft.com/profileapi-scope/Read.Profiles"], // e.g. api://xxxxxx/access_as_user
  },
};

export const protectedResources =
  process.env.REACT_APP_ENV === "local"
    ? protectedResources_local
    : process.env.REACT_APP_ENV === "development"
    ? protectedResources_dev
    : protectedResources_prod;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["openid"],
};

// API URL according to environment

export const API_URL =
  process.env.REACT_APP_ENV === "development"
    ? "https://users-dev.intracen.org/api/"
    : process.env.REACT_APP_ENV === "local"
    ? "https://users-dev.intracen.org/api/"
    : "https://users.intracen.org/api/";

// Redirect URL for Iframe

const RedirectLocalIframe = "http://localhost:3000/iframe";
const RedirectDevIframe = "https://users-dev.intracen.org/iframe";
const RedirectProdIframe = "https://users.intracen.org/iframe";

export const RedirectIframe =
  process.env.REACT_APP_ENV === "local"
    ? RedirectLocalIframe
    : process.env.REACT_APP_ENV === "development"
    ? RedirectDevIframe
    : RedirectProdIframe;

// Redirect URL

const RedirectLocal = "http://localhost:3000/editprofile";
const RedirectDev = "https://users-dev.intracen.org/editprofile";
const RedirectProd = "https://users.intracen.org/editprofile";

export const Redirect =
  process.env.REACT_APP_ENV === "local"
    ? RedirectLocal
    : process.env.REACT_APP_ENV === "development"
    ? RedirectDev
    : RedirectProd;
