import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "reactstrap";

function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

const SignOutButton = (props) => {
  const { instance } = useMsal();
  const { t } = props;
  return (
    <Button
      className="theme-color"
      style={{ color: "white" }}
      outline
      onClick={() => handleLogout(instance)}
    >
      {t("Logout")}
    </Button>
  );
};

export default SignOutButton;
