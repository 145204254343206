import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import backend from "i18next-http-backend";
import { API_URL } from "../AuthConfig";

// Instance for making requests

const instance = axios.create({
  withCredentials: false,
  baseURL: API_URL,
});

const loadResources = async (locale) => {
  return await instance
    .get(`AppTranslations/get/${locale}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

// Backend Options for I18n

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split("|");
      loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

// Initialize I18n

i18next
  .use(initReactI18next)
  .use(backend)
  .init({
    backend: backendOptions,
    fallbackLng: "en",
    debug: true,
    load: "languageOnly",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: false,
    },
  });

export default i18next;
