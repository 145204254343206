import Header from "./Header";
import Footer from "./Footer";
export default function PrivacyPolicy() {
  return (
    <div>
      <Header showLanguage={false} />
      <div
        style={{
          width: "80rem",
          margin: "0 auto",
          fontSize: "1.3rem",
        }}
        className="vstack"
      >
        <h3 style={{ marginTop: "30px" }}>ITC PRIVACY POLICY</h3>
        <div style={{ padding: "20px 100px" }} className="vstack text-start">
          <h5 className="JustifyTerms">
            <p>
              The International Trade Centre (hereinafter referred to as “ITC”,
              “we”, “us”, or “our”), with headquarters in Geneva, Switzerland,
              is a joint subsidiary organ of the United Nations and the World
              Trade Organization. Please read this privacy policy (the “Privacy
              Policy”) carefully before proceeding to access and use ITC's
              webpages, or creating an account, as it sets out the terms of a
              legally binding agreement between you and ITC.
            </p>
            <p>
              ITC recognizes the immense potential of new technologies for its
              trade-related technical assistance activities, and we aim to
              incorporate them into our activities. At the same time, we are
              aware of the importance of the United Nations Personal Data
              Protection and Privacy Principles and of putting them into effect.
              As a result, ITC has adopted this Privacy Policy that enables us
              to undertake our operations whilst securing your personal data.
              Through this Privacy Policy, we wish to inform you about the
              collection, retention, securing and processing of your personal
              data in the context of your registration, visit to and use of this
              website (the “Website”) regardless of where you visit it from or
              how you access it.
            </p>
            <p>
              ITC will process your data in a fair manner, in accordance with
              your consent and best interests and consistent with our mandate
              and governing instruments. Any personal data collected through
              your use of the Website, or disclosed to ITC by registrants of the
              Website, is processed for the purposes set forth in this Privacy
              Policy, or in the relevant pages of the Website. Your personal
              data will not be processed in ways that are incompatible with such
              purposes.
            </p>
          </h5>
          <div className="vstack JustifyTerms">
            <ol>
              <h4>
                <li className="nested-list-0">
                  INTRODUCTION
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        By using or registering ITC’s Website or associated
                        Products and Services you acknowledge and agree to this
                        Privacy Policy.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        Registered users of our Websites, Products and Services
                        are “Registrants”.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        It is important that you read this Privacy Policy,
                        together with any other privacy policy ITC may provide
                        on specific occasions when ITC is collecting or
                        processing personal data about you, so that you are
                        completely aware of how and why ITC is collecting and
                        processing your data.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        This Privacy Policy supplements other policies of ITC,
                        incl. of other websites operated by ITC, and is not
                        intended to supplant them. You must also check the{" "}
                        <a href="/terms-of-use/">Terms of Use</a>, as well as
                        any supplementary terms and conditions that may regulate
                        the use of this Website, the Products and Services
                        channelled throughout the same.
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  THE PERSONAL DATA WE COLLECT
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        Providing personal data is your choice. When you
                        register and create a user account with ITC, you
                        knowingly provide ITC with personal data about you.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        The type of personal data we ask you to provide will be
                        duly set out on the relevant page of the Website when
                        you register. This information may include your name,
                        surname, email address, and phone number, as well as
                        other personal data about you. Such personal data
                        relating to you are referred to as your “Personal Data”.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        Providing your Personal Data is voluntary, but failure
                        to provide certain Personal Data we request could
                        prevent you from using certain services or accessing
                        certain parts of the Website.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        In continuing to use the Website, you consent to ITC
                        collecting, storing and using additional information
                        about your computer, and your visits to and use of the
                        Website (including your IP address, geographical
                        location, browser type and version, operating system,
                        referral source, length of visit, page views and website
                        navigation paths). For more information on how we do
                        this, please see our Cookie Policy in section 11 below.
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  HOW WE USE YOUR PERSONAL DATA
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        The Personal Data you submit through the Website will be
                        only used for the purposes specified in this Privacy
                        Policy, or in the relevant pages of the Website when
                        applicable. Processing of Personal Data will be
                        relevant, limited and adequate to what is required for
                        these purposes.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC may use your Personal Data to:
                        <div className="vstack">
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">a{")"}</div>
                            <div className="alphabet-list-value">
                              provide you with access to the Website and
                              associated Products and Services;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">b{")"}</div>
                            <div className="alphabet-list-value">
                              administer the Website and activities;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">c{")"}</div>
                            <div className="alphabet-list-value">
                              generate analytical information for ITC about its
                              user base;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">d{")"}</div>
                            <div className="alphabet-list-value">
                              personalise the Website and associated Products
                              and Services for you;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">e{")"}</div>
                            <div className="alphabet-list-value">
                              enable your use of Products and Services available
                              on the Website;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">f{")"}</div>
                            <div className="alphabet-list-value">
                              send you non-marketing commercial communications
                              by email;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">g{")"}</div>
                            <div className="alphabet-list-value">
                              send you email or SMS notifications that you have
                              specifically requested;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">h{")"}</div>
                            <div className="alphabet-list-value">
                              send you email newsletters to which you have
                              subscribed (you can unsubscribe at any time);
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">i{")"}</div>
                            <div className="alphabet-list-value">
                              send you communications to you by email or similar
                              technology relating to our activities which we
                              think may be of interest (you can inform us at any
                              time if you no longer require such
                              communications);
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">j{")"}</div>
                            <div className="alphabet-list-value">
                              deal with enquiries and complaints made by or
                              about you relating to the Website;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">k{")"}</div>
                            <div className="alphabet-list-value">
                              keep the Website secure and prevent fraud; and
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">l{")"}</div>
                            <div className="alphabet-list-value">
                              verify compliance with the terms and conditions
                              governing the use of the Website.
                            </div>
                          </div>
                        </div>
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  CONFIDENTIALITY AND SECURITY OF PERSONAL DATA
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        ITC will process your Personal Data with due regard to
                        its confidentiality.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC will process Personal Data with transparency to you,
                        as appropriate and whenever possible.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC will take reasonable technical and organisational
                        precautions to prevent the loss, misuse or alteration of
                        your Personal Data.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC will store all the Personal Data that you provide on
                        our secure and encrypted servers.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        You acknowledge that the transmission of information
                        over the internet is inherently insecure, and ITC cannot
                        guarantee the security of data, including your Personal
                        Data, sent over the internet.{" "}
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        You are responsible for keeping confidential the
                        password you use for accessing our Website; we will not
                        ask you for your password via e-mail, SMS, phone or
                        other channels except when you log in through the
                        Website.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        Personal Data that ITC collects may be stored and
                        processed in and transferred between any of the
                        countries in which ITC undertakes its activities, in
                        order to enable ITC to use the Personal Data in
                        accordance with this Privacy Policy.
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  WHO WE DISCLOSE YOUR PERSONAL DATA TO
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        In carrying out our mandated activities, ITC may
                        disclose your Personal Data to any ITC officials or
                        third parties, including professional advisers,
                        consultants, suppliers, subcontractors and auditors,
                        insofar as reasonably necessary for the purposes set out
                        in this Privacy Policy, provided that, under the
                        circumstances, ITC is satisfied that the third party
                        affords appropriate protection for the data concerned.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC may disclose your Personal Data:
                        <div className="vstack">
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">a{")"}</div>
                            <div className="alphabet-list-value">
                              to the extent that ITC is required to do so by
                              applicable laws;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">b{")"}</div>
                            <div className="alphabet-list-value">
                              in connection with any ongoing or prospective
                              legal proceedings; or
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">c{")"}</div>
                            <div className="alphabet-list-value">
                              in order to establish, exercise or defend ITC’s
                              legal rights.
                            </div>
                          </div>
                        </div>
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC may disclose your Personal Data to any other agency
                        of the United Nations, other international
                        organizations, or public and private sector entities
                        insofar as reasonably necessary for the purposes set out
                        in this Privacy Policy. Any compliance with national
                        data protection legislation, or cooperation with
                        national or regional data protection authorities, is
                        always without prejudice to ITC’s privileges and
                        immunities under domestic and international law. You
                        acknowledge that ITC cannot be compelled to disclose any
                        information acquired while carrying out its work.
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>
              <h4>
                <li className="nested-list-0">
                  THIRD-PARTY WEBSITES
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        ITC’s Website may include links and references to
                        third-party websites. Clicking on those links or
                        enabling those connections may allow third parties to
                        collect or share data about you including your Personal
                        Data.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC has no control over, and is not responsible for,
                        these third-party websites or how they process data, and
                        ITC is not responsible for the privacy policies and
                        practices of any third party. ITC provides these links
                        only as a convenience, and the inclusion of a link or
                        reference does not imply the endorsement of the Linked
                        Website by ITC. When you leave the Website, ITC
                        encourages you to read the privacy policy of every
                        website you visit.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC does not share any personal data that you provide
                        during the registration process with third party
                        websites. This includes data about you transmitted
                        through the system, e.g. IP details, and data that you
                        provide when completing your user profile.
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>

              {/* Retention , Destruction */}
              <h4>
                <li className="nested-list-0">
                  RETENTION, DESTRUCTION, AND ARCHIVING OF PERSONAL DATA
                  <ol>
                    <h5>
                      <li className="nested-list-1">
                        Personal Data that ITC processes, for the purposes set
                        out in this Privacy Policy, shall not be retained for
                        longer than is necessary.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        You can request access, verification, rectification
                        and/or deletion of your Personal Data via our contact
                        details below (Section 11), insofar as the specified
                        purpose for which the Personal Data is processed is not
                        frustrated.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC will delete Personal Data when:
                        <div className="vstack">
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">a{")"}</div>
                            <div className="alphabet-list-value">
                              they are no longer necessary for the purposes for
                              which they were collected or otherwise further
                              processed; and/or
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">b{")"}</div>
                            <div className="alphabet-list-value">
                              you either withdraw your consent for processing,
                              or object to the processing or request deletion of
                              your Personal Data, via our Contact details below
                              (Section 11).
                            </div>
                          </div>
                        </div>
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        ITC reserves the right to retain in a deidentified form
                        any analytical or reporting data that it has derived
                        through the processing of your Personal Data.
                      </li>
                    </h5>
                    <h5>
                      <li className="nested-list-1">
                        Notwithstanding the other provisions of this section,
                        destruction of records containing your Personal Data may
                        be suspended, and documents retained (including
                        electronic documents), if any of the following apply:
                        <div className="vstack">
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">a{")"}</div>
                            <div className="alphabet-list-value">
                              there is a legitimate reason for archiving them,
                              including, for instance, but not limited to, when
                              Personal Data may be necessary for auditing or for
                              statistical, scientific or historical purposes;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">b{")"}</div>
                            <div className="alphabet-list-value">
                              to the extent that we are required to do so by
                              applicable law;
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">c{")"}</div>
                            <div className="alphabet-list-value">
                              if ITC believes that the documents containing your
                              Personal Data may be relevant to any ongoing or
                              prospective legal proceedings; and
                            </div>
                          </div>
                          <div className="nested-list-1 hstack">
                            <div className="alphabet-list-order">d{")"}</div>
                            <div className="alphabet-list-value">
                              in order to establish, exercise or defend ITC’s
                              legal rights.
                            </div>
                          </div>
                        </div>
                      </li>
                    </h5>
                  </ol>
                </li>
              </h4>

              {/* Cookies */}
              <h4>
                <li className="nested-list-0">COOKIES</li>
                <ol>
                  <h5>
                    <li className="nested-list-1">
                      You are hereby informed that, when you visit ITC’s
                      Website, or register or supply Personal Data through the
                      ITC’s Website, cookies may automatically be installed on
                      the browser software of your computer, mobile phone or
                      other device.
                    </li>
                  </h5>
                  <h5>
                    <li className="nested-list-1">
                      Cookies are small data files made up of letters and
                      numbers stored on your computer or other devices when you
                      access the Website. ITC uses cookies to collect
                      information about you to the extent necessary to ensure
                      its Website functionality and performance, as well as for
                      the purposes of targeting its audience and tailoring the
                      content offered to the public, both on the Website and
                      elsewhere. ITC sends cookies when you visit and use the
                      Website, download material, and/or request a Product or
                      Service or information. Accepting the cookies used on the
                      Website does not give ITC access to your personally
                      identifiable information, but ITC can use the cookies to
                      identify your computer. Encrypted information gathered
                      from cookies is used to understand the performance of the
                      Website, improve your experience while using it, and to
                      remind you of our activities. However, this information is
                      anonymous and based on user behaviour, and not on
                      personally distinguishable features.
                    </li>
                  </h5>
                  <h5>
                    <li className="nested-list-1">
                      If you do not wish to have cookies installed on your
                      computer or mobile device, you can set your browser to
                      notify you before you receive a cookie, giving you the
                      opportunity to decide whether to accept it or not. You can
                      also set your browser to turn off cookies. If you do so,
                      however, some areas of the Website may not function
                      properly.
                    </li>
                  </h5>
                  <h5>
                    <li className="nested-list-1">
                      Setting cookies allows ITC to provide you with a better
                      Product or Service, to improve your experience on the
                      Website and to understand how the Website and other
                      communication efforts are performing.
                    </li>
                  </h5>
                  <h5>
                    <li className="nested-list-1">
                      Some of the cookies you may receive when visiting the
                      Website are not related to ITC. For example, if you visit
                      sections of the Website that contain embedded content such
                      as a Twitter feed, or a plugin such as a Facebook ‘like’
                      button, as well as a directory member website link, you
                      may receive cookies delivered from these websites. ITC
                      does not govern the settings or use of these third-party
                      cookies, so ITC suggests that you visit these websites for
                      more information about their cookies and applicable cookie
                      and privacy policies. ITC cannot be held responsible for
                      the privacy of data collected by websites that it does not
                      own or manage, even if these websites link to the Website.
                      Modern web browsers provide the ability to disable
                      third-party cookies, or even all cookies.
                    </li>
                  </h5>
                  <h5>
                    <li className="nested-list-1">
                      In continuing to use the Website, you consent to ITC
                      collecting, storing and using additional information about
                      your computer, and your visits to and use of the Website
                      (including your IP address, geographical location, browser
                      type and version, operating system, referral source,
                      length of visit, page views and website navigation paths).{" "}
                    </li>
                  </h5>
                </ol>
              </h4>
              <h4>
                <li className="nested-list-0">ITC LEGAL STATUS</li>
                <ol>
                  <h5>
                    <li className="nested-list-1">
                      ITC enjoys privileges and immunities by the Convention on
                      the privileges and immunities of the United Nations
                      adopted by the General Assembly of the United Nations on
                      13th February 1946 (hereinafter the “Privileges and
                      Immunities”). 
                    </li>
                  </h5>
                  <h5>
                    <li className="nested-list-1">
                      Nothing contained in or relating to these Terms of Use
                      shall constitute or be considered to be a limitation upon
                      or a waiver, express or implied, of the Privileges and
                      Immunities of the ITC, which are specifically reserved.
                    </li>
                  </h5>
                </ol>
              </h4>
              <h4>
                <li className="nested-list-0">AMENDMENTS</li>
                <ol>
                  <h5>
                    <li className="nested-list-1">
                      We may update this Privacy Policy from time to time by
                      publishing a new version on the Website. ITC thus
                      encourages you to visit this section of the Website
                      regularly in order to keep up to date with any changes.
                      Changes to the Privacy Policy will apply from the date
                      they are posted on the Website and shall not apply
                      retroactively.
                    </li>
                  </h5>
                  <h5>
                    <li className="nested-list-1">
                      Should you provide your consent ITC may notify you of
                      changes to this Privacy Policy by email active on the
                      Website.
                    </li>
                  </h5>
                </ol>
              </h4>
              <h4>
                <li className="nested-list-0">CONTACT DETAILS</li>
                <h5 className="nested-list-1">
                  You can contact ITC to verify or rectify your Personal Data
                  using the ITC Website contact form{" "}
                  <a
                    href="https://www.intracen.org/contact-us/"
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    here
                  </a>{" "}
                  or by post to:
                  <br />
                  <br />
                  International Trade Centre
                  <br />
                  Palais des Nations
                  <br />
                  CH-1211 Geneva 10
                  <br />
                  Switzerland
                </h5>
              </h4>
              <h4>
                <li className="nested-list-0">VERSION DETAILS</li>
                <h5 className="nested-list-1">
                  This policy was last updated 01 November 2022 and supersedes
                  all previous versions.
                </h5>
              </h4>
            </ol>
          </div>
        </div>
      </div>
      <Footer showLinks={true} />
    </div>
  );
}
