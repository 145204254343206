import axios from "axios";
import { API_URL } from "../AuthConfig";

// Function to Fetch profile from backend

export const fetchProfile = async (response) => {
  const bearer = `Bearer ${response.accessToken}`;
  const headers = {
    Authorization: bearer,
    "Content-Type": "application/json",
  };
  var profile = await axios
    .get(API_URL + "UserProfiles/GetByEmail", {
      headers,
    })
    .then(async function (res) {
      if (res.status === 204) {
        return await createProfile(response);
      } else if (res.status === 200) {
        return res.data;
      }
    });
  return profile;
};

// Function to Save profile to backend

export const saveProfile = async (profile, accessToken) => {
  const bearer = `Bearer ${accessToken}`;
  const headers = {
    Authorization: bearer,
    "Content-Type": "application/json",
  };
  try {
    var res = await axios
      .put(API_URL + "UserProfiles/Update", profile, {
        headers,
      })
      .then(function (response) {
        return response;
      });
    return res;
  } catch (error) {
    return error;
  }
};

// Function to Create profile if the profile doesn't exist

async function createProfile(response) {
  const bearer = `Bearer ${response.accessToken}`;
  const headers = {
    Authorization: bearer,
    "Content-Type": "application/json",
  };
  try {
    if (response) {
      if (response.idTokenClaims) {
        var date = new Date().toISOString();
        date = date.substring(0, date.length - 1);
        var profile = {
          FirstName: response.idTokenClaims.given_name
            ? response.idTokenClaims.given_name
            : "",
          LastName: response.idTokenClaims.family_name
            ? response.idTokenClaims.family_name
            : "",
          Email: response.idTokenClaims.emails[0]
            ? response.idTokenClaims.emails[0]
            : "",
          RegistrationID: response.uniqueId ? response.uniqueId : "",
          UpdateDate: date,
        };
        var res = await axios.put(API_URL + "UserProfiles/Update", profile, {
          headers,
        });
      }
    }
    return res.data;
  } catch (error) {
    return error;
  }
}

// Function to get master list from backend for options

export const GetMasterList = async (accessToken) => {
  const bearer = `Bearer ${accessToken}`;
  const headers = {
    Authorization: bearer,
    "Content-Type": "application/json",
  };
  var res = await axios.get(API_URL + "ListMasters/GetListMaster", { headers });
  return res.data;
};
