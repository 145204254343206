import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ProfileForm from "./ProfileForm";
import { useMsal } from "@azure/msal-react";
import { fetchProfile, GetMasterList } from "../functions/ApiCalls";
import { protectedResources, loginRequest, Redirect } from "../AuthConfig";

export default function EditProfile() {
  const { instance } = useMsal();
  const [profile, setProfile] = useState({});
  const [MasterList, setMasterList] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  useEffect(() => {
    async function handleRedirect() {
      // Get All accounts in the msal Instance
      const accounts = await instance.getAllAccounts();
      if (accounts.length > 0) {
        // If any account then set the first account as active account
        await instance.setActiveAccount(accounts[0]);
      }

      await instance.addEventCallback(
        (event) => {
          // set active account after redirect
          if (
            event.eventType === "msal:loginSuccess" &&
            event.payload.account
          ) {
            const account = event.payload.account;
            instance.setActiveAccount(account);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );

      // handle auth redired/do all initial setup for msal
      instance
        .handleRedirectPromise()
        .then(async (authResult) => {
          // Check if user signed in
          const account = instance.getActiveAccount();
          if (!account) {
            // redirect anonymous user to login page
            await instance.loginRedirect({
              redirectUri: Redirect,
              scopes: loginRequest.scopes,
            });
          } else {
            // Acquire access token
            await instance
              .acquireTokenSilent({
                scopes: protectedResources.api.scopes,
                account: account,
              })
              .then(async (response) => {
                // Fill the necessary things by fetching the data from backend
                setAccessToken(response.accessToken);
                setMasterList(await GetMasterList(response.accessToken));
                setProfile(await fetchProfile(response));
              })
              .catch(async (error) => {
                await instance.loginRedirect({
                  redirectUri: Redirect,
                  scopes: loginRequest.scopes,
                });
                console.log(error);
              });
          }
        })
        .catch(
          async (err) => {
            // If any error found then redirect it to the login page
            await instance.loginRedirect({
              redirectUri: Redirect,
              scopes: loginRequest.scopes,
            });
            console.log(err);
          }
          // TODO: Handle errors
        );
    }
    handleRedirect();
  }, [instance]);

  return (
    <div>
      {profile.Email ? (
        <div>
          <Header showLanguage={true} masterlist={MasterList} />
          <div style={{ marginBottom: "8rem" }}>
            <ProfileForm
              accessToken={accessToken}
              masterlist={MasterList}
              profile={profile}
            />
          </div>
          <Footer showLinks={false} />
        </div>
      ) : (
        <div className="loading">
          <h2>Loading...</h2>
        </div>
      )}
    </div>
  );
}
